const welcomeCampaign: { [key: string]: { active: boolean, code: string, discount: number, slug: string, title: string, price: number } } = {
    'au': {
        'active': true,
        'title': 'Welcome40',
        'code': 'WELCOME40',
        'discount': 40,
        'slug': 'welcome40',
        'price': 60
    },
    'nz': {
        'active': true,
        'title': 'Welcome40',
        'code': 'WELCOME40',
        'discount': 40,
        'slug': 'welcome40',
        'price': 60
    },
    'ca': {
        'active': true,
        'title': 'Welcome40',
        'code': 'WELCOME40',
        'discount': 40,
        'slug': 'welcome40',
        'price': 60
    },
    'international': {
        'active': true,
        'title': 'Welcome40',
        'code': 'WELCOME40',
        'discount': 40,
        'slug': 'welcome40',
        'price': 60
    }
}

export const getWelcomeCampaing = (region: string) => {
    return welcomeCampaign[region];
}