import { RegionKey } from './HeaderNavigationRegionSelect';
import { HeaderNavigationCategory } from './Shared/useHeaderHelpers';

export const CATEGORY_LIST_BY_LOCALE: Record<
  RegionKey,
  HeaderNavigationCategory[]
> = {
  international: [
    {
      name: 'Glasses',
      bannerImage: 'glasses',
      items: [
        {
          name: 'Prescription Glasses',
          path: '/shop/prescription-glasses'
        },
        {
          name: 'Blue Light Filter Glasses',
          path: '/shop/blue-light-filter-glasses'
        },
        {
          name: 'Reading Glasses',
          path: '/shop/reading-glasses'
        }
      ]
    },
    {
      name: 'Sunglasses',
      bannerImage: 'sunglasses',
      items: [
        {
          name: 'Prescription Sunglasses',
          path: '/shop/prescription-sunglasses'
        },
        {
          name: 'Non Prescription  Sunglasses',
          path: '/shop/non-prescription-sunglasses'
        },
      ]
    },
    {
      name: 'Frames',
      bannerImage: '/images/header-banners/frames_nz_header.jpg',
      items: [
        {
          name: 'Frames',
          path: '/shop/frames'
        },
        {
          name: 'Arms',
          path: '/shop/spare-parts'
        },
        {
          name: 'Pin Garages',
          path: '/shop/accessories/pin-garages'
        }
      ]
    },
    {
      name: 'Accessories',
      bannerImage: 'accessories',
      items: [
        {
          name: 'Felt Cases',
          path: '/shop/accessories/felt-cases'
        },
        {
          name: 'Tote Bag',
          path: '/shop/accessories/tote-bags'
        },
        {
          name: 'Cleaning Cloths',
          path: '/shop/accessories/cleaning-cloths'
        },
        {
          name: 'Lens Sprays & Wipes',
          path: '/shop/accessories/lens-sprays-and-wipes'
        },
        // {
        //   name: 'Eye Care',
        //   path: '/shop/accessories/eye-care'
        // },
        {
          name: 'Glasses Clips',
          path: '/shop/accessories/glasses-clips'
        },
        {
          name: 'Flex Accessory Clips',
          path: '/shop/accessories/flex-accessory-clips'
        },
      ]
    }
  ],
  au: [
    {
      name: 'Glasses',
      bannerImage: '/images/header-banners/glasses_banner_new_pair.jpg',
      items: [
        {
          name: 'Prescription Glasses',
          path: '/shop/prescription-glasses'
        },
        {
          name: 'Blue Light Filter Glasses',
          path: '/shop/blue-light-filter-glasses'
        },
        {
          name: 'Reading Glasses',
          path: '/shop/reading-glasses'
        }
      ]
    },
    {
      name: 'Sunglasses',
      // bannerImage: '/images/header-banners/sunglasses_banner.jpg',
      bannerImage: '/images/header-banners/valentine_banner.jpg',
      items: [
        {
          name: 'Prescription Sunglasses',
          path: '/shop/prescription-sunglasses '
        },
        {
          name: 'Non Prescription Sunglasses',
          path: '/shop/non-prescription-sunglasses'
        },
      ]
    },
    {
      name: 'Contact Lenses',
      bannerImage: '/images/header-banners/contact_lenses_banner.jpg',
      sections: [
        {
          name: "Type",
          items: [
            {
              name: 'Daily',
              path: '/shop/contact-lenses/frequency/daily'
            },
            {
              name: 'Fortnightly',
              path: '/shop/contact-lenses/frequency/fortnightly'
            },
            {
              name: 'Monthly',
              path: '/shop/contact-lenses/frequency/monthly'
            },
            {
              name: 'Multifocal',
              path: '/shop/contact-lenses/type/multifocal'
            },
            {
              name: 'Toric',
              path: '/shop/contact-lenses/type/toric'
            },
            {
              name: 'View All Contacts',
              path: '/shop/contact-lenses'
            }
          ]
        },
        {
          name: "Brand",
          items: [
            {
              name: 'Acuvue',
              path: '/shop/contact-lenses/brand/acuvue'
            },
            {
              name: 'Air Optix',
              path: '/shop/contact-lenses/brand/air-optix'
            },
            {
              name: 'Biofinity',
              path: '/shop/contact-lenses/brand/biofinity'
            },
            {
              name: 'Dailies',
              path: '/shop/contact-lenses/brand/dailies'
            },
            // {
            //   name: 'Soflens',
            //   path: '/shop/contact-lenses/brand/soflens'
            // },
            {
              name: 'Proclear',
              path: '/shop/contact-lenses/brand/proclear'
            },
            {
              name: 'View All Contacts',
              path: '/shop/contact-lenses'
            }
          ]
        }
      ]
    },
    {
      name: 'Accessories',
      bannerImage: '/images/header-banners/accessories_banner_new.jpg',
      items: [
        {
          name: 'Frames',
          path: '/shop/frames'
        },
        {
          name: 'Arms',
          path: '/shop/spare-parts'
        },
        {
          name: 'Felt Cases',
          path: '/shop/accessories/felt-cases'
        },
        {
          name: 'Tote Bag',
          path: '/shop/accessories/tote-bags'
        },
        {
          name: 'Pin Garages',
          path: '/shop/accessories/pin-garages'
        },
        {
          name: 'Cleaning Cloths',
          path: '/shop/accessories/cleaning-cloths'
        },
        {
          name: 'Lens Sprays & Wipes',
          path: '/shop/accessories/lens-sprays-and-wipes'
        },
        {
          name: 'Gift Cards',
          path: '/giftcard-sale'
        },
        {
          name: 'Eye Care',
          path: '/shop/accessories/eye-care'
        },
        {
          name: 'Glasses Clips',
          path: '/shop/accessories/glasses-clips'
        },
        {
          name: 'Flex Accessory Clips',
          path: '/shop/accessories/flex-accessory-clips'
        },
      ]
    }
  ],
  ca: [
    {
      name: 'Glasses',
      bannerImage: '/images/header-banners/glasses_banner_new_pair.jpg',
      items: [
        {
          name: 'Prescription Glasses',
          path: '/shop/prescription-glasses '
        },
        {
          name: 'Blue Light Filter Glasses',
          path: '/shop/blue-light-filter-glasses'
        },
        {
          name: 'Reading Glasses',
          path: '/shop/reading-glasses'
        }
      ]
    },
    {
      name: 'Sunglasses',
      // bannerImage: '/images/header-banners/sunglasses_banner.jpg',
      bannerImage: '/images/header-banners/valentine_banner.jpg',
      items: [
        {
          name: 'Prescription Sunglasses',
          path: '/shop/prescription-sunglasses '
        },
        {
          name: 'Non Prescription Sunglasses',
          path: '/shop/non-prescription-sunglasses'
        },
      ]
    },
    {
      name: 'Contact Lenses',
      bannerImage: '/images/header-banners/contact_lenses_banner.jpg',
      sections: [
        {
          name: "Type",
          items: [
            {
              name: 'Daily',
              path: '/shop/contact-lenses/frequency/daily'
            },
            {
              name: 'Fortnightly',
              path: '/shop/contact-lenses/frequency/fortnightly'
            },
            {
              name: 'Monthly',
              path: '/shop/contact-lenses/frequency/monthly'
            },
            {
              name: 'Multifocal',
              path: '/shop/contact-lenses/type/multifocal'
            },
            {
              name: 'Toric',
              path: '/shop/contact-lenses/type/toric'
            },
            {
              name: 'View All Contacts',
              path: '/shop/contact-lenses'
            }
          ]
        },
        {
          name: "Brand",
          items: [
            {
              name: 'Acuvue',
              path: '/shop/contact-lenses/brand/acuvue'
            },
            {
              name: 'Air Optix',
              path: '/shop/contact-lenses/brand/air-optix'
            },
            // {
            //   name: 'Biofinity',
            //   path: '/shop/contact-lenses/brand/biofinity'
            // },
            {
              name: 'Dailies',
              path: '/shop/contact-lenses/brand/dailies'
            },
            {
              name: 'Ultra',
              path: '/shop/contact-lenses/brand/ultra'
            },
            /*{
              name: 'Proclear', 
              path: '/shop/contact-lenses/brand/proclear'
            },*/
            {
              name: 'View All Contacts',
              path: '/shop/contact-lenses'
            }
          ]
        }
      ]
    },
    {
      name: 'Accessories',
      bannerImage: '/images/header-banners/accessories_banner_new.jpg',
      items: [
        {
          name: 'Frames',
          path: '/shop/frames'
        },
        {
          name: 'Arms',
          path: '/shop/spare-parts'
        },
        {
          name: 'Felt Cases',
          path: '/shop/accessories/felt-cases'
        },
        {
          name: 'Lens Sprays & Wipes',
          path: '/shop/accessories/lens-sprays-and-wipes'
        },
        {
          name: 'Gift Cards',
          path: '/giftcard-sale'
        },
        {
          name: 'Tote Bag',
          path: '/shop/accessories/tote-bags'
        },
      ]
    }
  ],
  nz: [
    {
      name: 'Glasses',
      bannerImage: '/images/header-banners/glasses_banner_new_pair.jpg',
      items: [
        {
          name: 'Prescription Glasses',
          path: '/shop/prescription-glasses '
        },
        {
          name: 'Blue Light Filter Glasses',
          path: '/shop/blue-light-filter-glasses'
        },
        {
          name: 'Reading Glasses',
          path: '/shop/reading-glasses'
        }
      ]
    },
    {
      name: 'Sunglasses',
      // bannerImage: '/images/header-banners/sunglasses_banner.jpg',
      bannerImage: '/images/header-banners/valentine_banner.jpg',
      items: [
        {
          name: 'Prescription Sunglasses',
          path: '/shop/prescription-sunglasses '
        },
        {
          name: 'Non Prescription  Sunglasses',
          path: '/shop/non-prescription-sunglasses'
        },
      ]
    },
    {
      name: 'Frames',
      bannerImage: '/images/header-banners/frames_nz_header.jpg',
      items: [
        {
          name: 'Frames',
          path: '/shop/frames'
        },
        {
          name: 'Arms',
          path: '/shop/spare-parts'
        },
        {
          name: 'Pin Garages',
          path: '/shop/accessories/pin-garages'
        }
      ]
    },
    {
      name: 'Accessories',
      bannerImage: '/images/header-banners/accessories_banner_new.jpg',
      items: [
        {
          name: 'Felt Cases',
          path: '/shop/accessories/felt-cases'
        },
        {
          name: 'Tote Bag',
          path: '/shop/accessories/tote-bags'
        },
        {
          name: 'Pin Garages',
          path: '/shop/accessories/pin-garages'
        },
        {
          name: 'Cleaning Cloths',
          path: '/shop/accessories/cleaning-cloths'
        },
        {
          name: 'Lens Sprays & Wipes',
          path: '/shop/accessories/lens-sprays-and-wipes'
        },
        {
          name: 'Gift Cards',
          path: '/giftcard-sale'
        },
        {
          name: 'Eye Care',
          path: '/shop/accessories/eye-care'
        },
        {
          name: 'Glasses Clips',
          path: '/shop/accessories/glasses-clips'
        },
        {
          name: 'Flex Accessory Clips',
          path: '/shop/accessories/flex-accessory-clips'
        },
      ]
    }
  ]
};
